<template>
  <v-card>
    <v-card-title>
      Create Referral Code
    </v-card-title>

    <RewardForm 
      type="CREATE"
      :referral="referral"
    />
  </v-card>
</template>

<script>
import RewardForm from "./form.vue"

export default {
  components: {
    RewardForm
  },
  data() {
    return {
      referral: {
        code: null,
        created_by: this.$store.state.userProfile.name,
        start: null,
        expiry: null,
        browser_history: "false"
      },
    };
  },
};
</script>
